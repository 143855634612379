// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-app-oauth-2-callback-js": () => import("../src/pages/app/oauth2/callback.js" /* webpackChunkName: "component---src-pages-app-oauth-2-callback-js" */),
  "component---src-pages-classroom-resources-old-js": () => import("../src/pages/classroom-resources-old.js" /* webpackChunkName: "component---src-pages-classroom-resources-old-js" */),
  "component---src-pages-classroom-resources-js": () => import("../src/pages/classroom-resources.js" /* webpackChunkName: "component---src-pages-classroom-resources-js" */),
  "component---src-pages-family-resources-js": () => import("../src/pages/family-resources.js" /* webpackChunkName: "component---src-pages-family-resources-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-master-class-js": () => import("../src/pages/master-class.js" /* webpackChunkName: "component---src-pages-master-class-js" */),
  "component---src-pages-virtual-field-trip-js": () => import("../src/pages/virtual-field-trip.js" /* webpackChunkName: "component---src-pages-virtual-field-trip-js" */)
}

